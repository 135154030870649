import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DesktopDashboardLayout: () =>
      import("./components/DesktopDashboardLayout/DesktopDashboardLayout.vue"),
    MobileDashboardLayout: () =>
      import("./components/MobileDashboardLayout/MobileDashboardLayout.vue"),
  },
})
export default class DashboardLayout extends Vue {}
